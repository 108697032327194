'use client';

import 'client-only';
import { createApiClient } from '@prismo-io/core';
import { useRouter } from 'next/navigation';
import { type FC, useEffect } from 'react';

// this component handles refreshing server data when the user logs in or out
// this method avoids the need to pass a session down to child components
// in order to re-render when the user's session changes
// #elegant!

type Props = {
  serverAccessToken?: string;
};
export const SessionListener: FC<Props> = ({
  serverAccessToken
}) => {
  const router = useRouter();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const client = createApiClient();
    client.auth.onAuthStateChange((event, session) => {
      // console.log('Event', event, 'expires_at', session?.expires_at);

      if (event === 'SIGNED_IN' || event === 'SIGNED_OUT') {
        router.refresh();
      }
    });
  }, []);
  return null;
};